import PrimeVue, { defaultOptions, PrimeVueChangeTheme, PrimeVueConfiguration } from 'primevue/config';
import { usePassThrough } from 'primevue/passthrough';
import Tailwind from 'primevue/passthrough/tailwind';
import { toRaw } from 'vue';
import i18n from '@/plugins/i18n';
import { button } from '@/plugins/primeVue/pt/button';
import { calendar } from '@/plugins/primeVue/pt/calendar';
import { datatable } from '@/plugins/primeVue/pt/datatable';
import { dialog } from '@/plugins/primeVue/pt/dialog';
import { dropdown } from '@/plugins/primeVue/pt/dropdown';
import { inputswitch } from '@/plugins/primeVue/pt/inputswitch';
import { inputtext } from '@/plugins/primeVue/pt/inputtext';
import { menu } from '@/plugins/primeVue/pt/menu';
import { menubar } from '@/plugins/primeVue/pt/menubar';
import { message } from '@/plugins/primeVue/pt/message';
import { multiselect } from '@/plugins/primeVue/pt/multiselect';
import { password } from '@/plugins/primeVue/pt/password';
import { selectbutton } from '@/plugins/primeVue/pt/selectbutton';
import { slider } from '@/plugins/primeVue/pt/slider';
import { stepper } from '@/plugins/primeVue/pt/stepper';
import { tabview } from '@/plugins/primeVue/pt/tabview';
import { tooltip } from '@/plugins/primeVue/pt/tooltip';

const CustomTailwind = usePassThrough(
  Tailwind,
  {
    calendar,
    datatable,
    dialog,
    dropdown,
    inputtext,
    password,
    selectbutton,
    directives: {
      tooltip,
    },
    button,
    multiselect,
    menu,
    inputswitch,
    menubar,
    tabview,
    slider,
    stepper,
    message,
  },
  {
    mergeSections: false,
    mergeProps: false,
  }
);

const primeVuePlugin = {
  install: (app) => {
    app.use(PrimeVue, {
      unstyled: true,
      pt: CustomTailwind,
      locale: {
        ...defaultOptions.locale,
      },
    });
  },
};

export function setPrimeVueLanguage(primeVueInstance: { config: PrimeVueConfiguration; changeTheme: PrimeVueChangeTheme }, languageCode: string) {
  const baseKey = 'primeVue';
  const translationFn = i18n.global.t as (key: string) => string;

  const primeVueMessages = toRaw(i18n.global.getLocaleMessage(languageCode)[baseKey]);

  if (!primeVueMessages || !primeVueInstance || !primeVueInstance.config || !primeVueInstance.config.locale) {
    return;
  }

  function setNestedProperty(obj: Record<string, any>, path: string, value: any) {
    const keys = path.split('.');

    let current = obj;
    while (keys.length > 1) {
      const key = keys.shift()!;
      if (!current[key]) {
        current[key] = {};
      }
      current = current[key];
    }

    if (keys[0]) {
      current[keys[0]] = value;
    }
  }

  function updateLocale(messages: any, key?: string) {
    Object.keys(messages).forEach((messageKey) => {
      const value = messages[messageKey];
      const translationKey = `${baseKey}` + (key ? `.${key}` : '') + `.${messageKey}`;
      const primeVueConfigKey = key ? `${key}.${messageKey}` : messageKey;

      if (!primeVueInstance.config.locale) return;

      if (typeof value === 'function') {
        setNestedProperty(primeVueInstance.config.locale, primeVueConfigKey, translationFn(translationKey));
      } else if (Array.isArray(value)) {
        const arrayOfMessages: string[] = [];
        for (let i = 0; i < value.length; i++) {
          arrayOfMessages.push(translationFn(`${translationKey}.${i}`));
        }
        setNestedProperty(primeVueInstance.config.locale, primeVueConfigKey, arrayOfMessages);
      } else if (typeof value === 'object' && value !== null) {
        updateLocale(value, primeVueConfigKey);
      } else {
        setNestedProperty(primeVueInstance.config.locale, primeVueConfigKey, value);
      }
    });
  }

  updateLocale(primeVueMessages);
}

export default primeVuePlugin;
