<template>
  <div class="div-primary mx-auto mt-4">
    <h1 class="mb-12 text-xl font-bold text-main md:text-3xl 2xl:text-4xl">{{ $t('conditionProducts.title') }}</h1>
    <div class="grid gap-5 xl:grid-cols-2">
      <div class="mx-auto h-full w-fit rounded-lg">
        <img class="mx-auto mb-1 h-10" :src="$filePath + '/logos/google.png'" :alt="EnumsAdvertisementPlatform.Google" />
        <VueApexCharts ref="googleChart" type="pie" :options="chartOptions" :series="googleSeries" height="250" :width="graphWidth" />
        <span @click="getIssues(EnumsProductIssueSource.Gmc)" class="mx-auto mt-2 block w-fit cursor-pointer text-center text-gray-600 hover:underline">{{ $t('conditionProducts.showMistakes') }}</span>
      </div>
      <div class="mx-auto mt-10 h-full w-fit rounded-lg xl:mt-0">
        <img class="mx-auto mb-4 h-8 w-auto" :src="$filePath + '/logos/meta.png'" :alt="EnumsAdvertisementPlatform.Meta" />
        <VueApexCharts ref="facebookChart" type="pie" :options="chartOptions" :series="metaSeries" height="250" :width="graphWidth" />
        <span @click="getIssues(EnumsProductIssueSource.Meta)" class="mx-auto mt-2 block w-fit cursor-pointer text-center text-gray-600 hover:underline">{{ $t('conditionProducts.showMistakes') }}</span>
      </div>
      <!-- MICROSOFT WAITING FOR BE
      <div class="col-span-2 mx-auto mt-10 h-full w-fit rounded-lg xl:mt-10">
        <img class="mx-auto mb-4 h-10 w-auto" :src="$filePath + '/logos/MicrosoftLogoFullWideText.png'" :alt="EnumsAdvertisementPlatform.Microsoft" />
        <VueApexCharts ref="microsoftChart" type="pie" :options="chartOptions" :series="microsoftSereis" height="250" :width="graphWidth" />
        <span @click="getIssues(EnumsProductIssueSource.Mmc)" class="mx-auto mt-2 block w-fit cursor-pointer text-center text-gray-600 hover:underline">{{ $t('conditionProducts.showMistakes') }}</span>
      </div>-->
    </div>
    <div class="ml-auto mr-0 mt-10">
      <p class="text-right text-xs text-main">{{ $t('conditionProducts.reloadTime') }}</p>
    </div>
  </div>
  <Dialog v-model:visible="visibleErrorDetail" modal header="" :breakpoints="{ '1199px': '90vw', '575px': '90vw' }">
    <template #header>
      <span> </span>
    </template>
    <PlatformIssuesDataTable :issueSource="EnumsProductIssueSource.Dotnet" />
    <PlatformIssuesDataTable :issueSource="issueSource" />
    <!--<p class="text-center text-main text-base font-bold">{{ $t('conditionProducts.noData') }}</p>-->
    <template #footer> </template>
  </Dialog>
</template>
<script setup lang="ts">
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import VueApexCharts from 'vue3-apexcharts';
import PlatformIssuesDataTable from '@/modules/dashboard/components/PlatformIssuesDataTable.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { EnumsProductIssueSource, EnumsAdvertisementPlatform } from '../../../../generated/api';

onMounted(async () => {
  await getGraphData();
});

const { t } = useI18n();
const toast = useToast();
const eshopsStore = useEshopsStore();

const visibleErrorDetail = ref(false);

const googleSeries = ref([]);
const metaSeries = ref([]);
const microsoftSeries = ref([]);

const issueSource = ref();

watch(
  () => eshopsStore.getSelectedEshop?.id,
  async () => {
    await getGraphData();
  }
);

const getGraphData = async () => {
  try {
    const { data } = await api.clientDashboardGetProductsConditionCount();
    googleSeries.value = [data.googleProducts?.active, data.googleProducts.disapproved, data.googleProducts.waiting, data.googleProducts.paused, data.googleProducts.invalid];
    metaSeries.value = [data.metaProducts?.active, data.metaProducts.disapproved, data.metaProducts.waiting, data.metaProducts.paused, data.metaProducts.invalid];
    microsoftSeries.value = [data.microsoftProducts?.active, data.microsoftProducts.disapproved, data.microsoftProducts.waiting, data.microsoftProducts.paused, data.microsoftProducts.invalid];
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    }
  }
};

const getIssues = async (source) => {
  issueSource.value = source;
  visibleErrorDetail.value = true;
};

const graphWidth = ref(window.innerWidth > 450 ? 450 : 200);

const chartOptions = ref({
  chart: {
    type: 'pie',
  },
  labels: [t('conditionProducts.statusSuccess'), t('conditionProducts.statusError'), t('conditionProducts.statusWaiting'), t('conditionProducts.statusPaused'), t('conditionProducts.statusInvalid')],
  colors: ['#16a34a', '#dc2626', '#075695', '#ea580c', '#f87171'],

  legend: {
    show: true,
    position: window.innerWidth > 600 ? 'left' : 'bottom',
    horizontalAlign: 'center',
    floating: false,
    formatter: function (val, opts) {
      return val + ' - ' + opts.w.globals.series[opts.seriesIndex];
    },
    fontSize: '13px',
    labels: {
      useSeriesColors: true,
    },
    onItemClick: {
      toggleDataSeries: false,
    },
    itemMargin: {
      horizontal: 8,
      vertical: 5,
    },
  },
  dataLabels: {
    enabled: false,
  },
});
</script>
